<script setup>
import { onMounted, reactive, toRefs, ref } from "vue";
import imgSuccess from "@/assets/paypay/success.png";
import { useRouter, useRoute } from "vue-router";
import Store from "store";

//查看订单
let router = useRouter();
let route = useRoute();
let viewOrder = () => {
  router.push("/store?order=1");
};
// 获取 token
let token;
if (process.env.VUE_APP_CUSTOM_ENV === "development") {
  token = process.env.VUE_APP_TOKEN;
} else {
  token = Store.get("diyToken");
}

//继续购物
let continueShopping = () => {
  // window.open(process.env.VUE_APP_URL_PRODUCTS)
  window.location.href = `${process.env.VUE_APP_URL_PRODUCTS}?diyToken=${token}`;
};
//没有 recharge 支付成功
// 有 充值成功

const { orderNumber, orderAmount } = route.query;

let recharge = Object.keys(route.query)[0] === "recharge";

let checkBalance = () => {
  router.push("/myAccount");
};

let orderNumberShow = sessionStorage.getItem("disposableOrderNumber");

let show = ref(false);
show.value = sessionStorage.getItem("disposableShow");
let orderInit = () => {
  //有无订单号
  if (!orderNumberShow) {
    //没有订单号存一个
    sessionStorage.setItem("disposableOrderNumber", orderNumber);
  } else {
    //有订单号 是否一样
    if (orderNumberShow !== orderNumber) {
      //订单号不一样
      sessionStorage.setItem("disposableShow", false);
      show.value = false;
    } else {
      //订单号一样
      sessionStorage.setItem("disposableShow", true);
      show.value = true;
    }
  }

  if (!show.value && !recharge.value) {
    //谷歌广告代码
    gtag("event", "conversion", {
      send_to: "AW-10926694714/Gw4fCJOP7cUDELrCoNoo",
      transaction_id: orderNumber,
    });

    //广告埋点 Reddit Pixel
    rdt("track", "Purchase");
  }
};

onMounted(() => {
  orderInit();
  window.addEventListener("beforeunload", (e) => beforeunloadHandler(e));
  // gtag("event", "conversion", {
  //   send_to: "AW-10924259660/E8aSCM_838EDEMzyi9ko",
  // });
});
//

let beforeunloadHandler = () => {
  //订单号 是否一样
  if (orderNumberShow !== orderNumber) {
    sessionStorage.setItem("disposableShow", false);
    sessionStorage.setItem("disposableOrderNumber", orderNumber);
  } else {
    sessionStorage.setItem("disposableShow", true);
  }
};

let url = `https://www.shareasale.com/sale.cfm?tracking=${orderNumber}&amount=${orderAmount}&merchantID=126376&transtype=sale`;
</script>

<template>
  <div class="paypay_success_box">
    <div class="paypay_box">
      <el-image :src="imgSuccess"></el-image>
      <div class="text_box">
        <template v-if="!recharge">
          <div class="text">Congratulations</div>
          <div class="text">successfully paid the order</div>
        </template>
        <template v-else>
          <div class="text">successful recharge!</div>
        </template>
      </div>
      <div class="btn_box">
        <template v-if="!recharge">
          <el-button class="continueShopping" @click="continueShopping"
            >Continue shopping</el-button
          >
          <el-button type="info" @click="viewOrder">View order</el-button>
        </template>
        <template v-else>
          <el-button class="continueShopping" @click="checkBalance"
            >Check balance</el-button
          >
        </template>
      </div>
    </div>
  </div>
  <img :src="url" width="1" height="1" v-if="!show && !recharge" />
</template>

<style scoped lang="scss">
.paypay_success_box {
  display: flex;
  justify-content: center;
  .paypay_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    margin-top: 180px;
    .btn_box {
      margin-top: 60px;
      .continueShopping {
        width: 180px;
        background: #ff2379;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .text_box {
      margin-top: 20px;
      text-align: center;
      .text {
        font-size: 23px;
        font-weight: 600;
        color: #000000;
        line-height: 33px;
      }
    }
  }
}
</style>
